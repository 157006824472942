import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import AdvancedSettings from "../settings/advanced_settings.json";
import ServiceHero from '../components/ServiceHero'
import MainContentBlockController from '../components/MainContentBlockController'
import SEO from '../components/SEO'


const ServicePageTemplate = ({
  title,
  metatitle,
  metadescription,
  metakeywords,
  description,
  featuredimage,
  featuredbadges,
  maincontentblocks,
  data,
  headerTransparent
}) => {
 
  return (
   <> 
    <ServiceHero headerTransparent={headerTransparent} type={AdvancedSettings.serviceherostyle} title={title} description={description} featuredimage={featuredimage} featuredbadges={featuredbadges}/>
    {maincontentblocks ? maincontentblocks.map((contentblock, i)=> (
          <MainContentBlockController key={i} contentblockID={contentblock.type} data={contentblock}/>
      )) : null}
    </>
    
  );
};

const ServicePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark; 

  return (
    <Layout headerTransparent={true}>
        <SEO
        title={frontmatter.metatitle}
        keywords={frontmatter.metakeywords.join(", ")}
        description={frontmatter.metadescription}
        img={frontmatter.featuredimage.childImageSharp.fixed.src} 
      />
      <ServicePageTemplate
        title={frontmatter.title}
        metatitle={frontmatter.metatitle}
        metadescription={frontmatter.metadescription}
        metakeywords={frontmatter.metakeywords}
        description={frontmatter.description}
        featuredimage={frontmatter.featuredimage}
        featuredbadges={frontmatter.headerfeaturedbadges}
        maincontentblocks={frontmatter.maincontentblocks}
        data={data}
        headerTransparent={true} 
      />
    </Layout>
  );
};

export default ServicePage;

export const ServicePageQuery = graphql`
  query ServicePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        metatitle
        metadescription
        metakeywords
        description
        headerfeaturedbadges {
          image {
            id
            childImageSharp {
              fluid(maxWidth: 110, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            extension
            publicURL
          }
          imgtitle
        }
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1366, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 1200, height: 630) {
              width
              height
              src
              srcSet
              srcWebp
            }
          }
        }
        maincontentblocks {
          type
          label
          title 
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              } 
            }
          }
          value
          description
          contentwithsidebarsidebar {
            sidebar {
              frontmatter { 
                title
                bodycontent 
                dynamicsidebarid
                renderashtml
                outercontainercss
                id
              }
            }
            sidebarcollection {
              frontmatter {
                title
                sidebars {
                  sidebar {
                    frontmatter {
                      title
                bodycontent 
                dynamicsidebarid
                renderashtml
                outercontainercss
                id
                    }
                  }
                }
              }
            }
          }
          bodycontent
          photogallery {
            frontmatter {
              title
              description
              images {
                imgtitle
                image {
                  childImageSharp {
                    fluid(maxWidth: 1300, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    fixed(width: 135, height: 118, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }

          footerlogoshowcase {
            frontmatter {
              title
              images {
                imgtitle
                image {
                  publicURL
                  extension
                  childImageSharp {
                    fluid(maxHeight: 250, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
